import FarmShareBG from '../assets/FarmshareBG.jpg';
import Navbar from '../components/Navbar';
import { useSelector, useDispatch } from 'react-redux';
import { getAllUsers, deleteUser, updateUser } from '../redux/slices/userSlice';
import { useEffect, useState } from 'react';
import DeleteUserModal from '../components/DeleteUserModal';
import UpdateUserModal from '../components/UpdateUserModal';
import AddUserModal from '../components/AddUserModal';
import UserTable from '../components/UserTable';


export default function UserList() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const loading = useSelector((state) => state.users.loading);
  const error_message = useSelector((state) => state.users.error);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [changeUser, setChangeUser] = useState(null);
  const [selectRole, setSelectRole] = useState('user');


  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const roleOrder = { admin: 1, user: 2 };
  const sortedUsers = [...users].sort((a, b) => {
    const roleComparison = roleOrder[a.role] - roleOrder[b.role];
    if (roleComparison !== 0) return roleComparison;
    return a.email.localeCompare(b.email);
  });

  // sortedUsers.map((user)=> {
  //   if (typeof user.firstName === 'undefined'){
  //     console.log(user.email)
  //   }
  // });

  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(updateUser({ userId: changeUser, role: selectRole }));
    setUpdateModal(false);
    setChangeUser(null);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(deleteUser(changeUser));
    setDeleteModal(false);
    setChangeUser(null);
  };

  return (
    <div className="bg-sky-200">
      <Navbar src={FarmShareBG} pageName={`User List`} pageText={''} />
      {updateModal && (
        <UpdateUserModal
          setSelectRole={setSelectRole}
          selectRole={selectRole}
          setUpdateModal={setUpdateModal}
          setChangeUser={setChangeUser}
          handleUpdate={handleUpdate}
        />
      )}
      {deleteModal && (
        <DeleteUserModal
          handleDelete={handleDelete}
          setDeleteModal={setDeleteModal}
          setChangeUser={setChangeUser}
        />
      )}
      {addUserModal && <AddUserModal setAddUserModal={setAddUserModal} />}
      {!error_message && (
        <UserTable
          sortedUsers={sortedUsers}
          setDeleteModal={setDeleteModal}
          setUpdateModal={setUpdateModal}
          setChangeUser={setChangeUser}
          loading={loading}
          setAddUserModal={setAddUserModal}
        />
      )}
      {error_message && (
        <div className="flex justify-center">
          <p className="text-red-600 font-bold">
            {error_message} - Please Try Again
          </p>
        </div>
      )}
    </div>
  );
}
