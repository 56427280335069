import FarmShareBG from '../assets/FarmshareBG.jpg';
import Navbar from '../components/Navbar';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getAllReservations,
  updateReservation,
} from '../redux/slices/reservationSlice';
import { getSpecificUser } from '../redux/slices/userSlice';
import { getSingleEquipment } from '../redux/slices/equipmentsSlice';
import ReservationFilter from '../components/ReservationFilter';
import ReservationCard from '../components/ReservationCard';
import ReservationModal from '../components/ReservationModal';
import ReservationInfoModal from '../components/ReservationInfoModal';
import { toast } from 'react-toastify';
import { ReactComponent as DwnldArrow } from '../assets/download-arrow.svg';
import { CSVLink } from 'react-csv';

//TODO: edit details of a reservation (pickup, person, person's truck load cap, etc.). Display these details too. Need to update model schema.

export default function ReservationList() {
  const reservations = useSelector((state) => state.reservations.reservations);
  const loading = useSelector((state) => state.reservations.loading);
  const error = useSelector((state) => state.reservations.error);
  const [users, setUsers] = useState({});
  const [equipments, setEquipments] = useState({});
  const [filteredReservations, setFilteredReservations] = useState(null);
  const [approveModal, setApproveModal] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('all');
  const [denyModal, setDenyModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [deleteReservationModal, setDeleteReservationModal] = useState(false);
  const [archiveRes, setArchiveRes] = useState(null);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [downloadReservations, setDownloadReservations] = useState(null);
  const [downloadReady, setDownloadReady] = useState(false);
  const [showReservationInfoModal, setShowReservationInfoModal] =
    useState(false);
  const dispatch = useDispatch();

  // const filterReservations = (key) => {
  // if (key === 'all') {
  //   let filtered = reservations.filter(
  //     (reservation) => reservation.archived === false,
  //   );
  //   setFilteredReservations(filtered);
  // } else if (key === 'archived') {
  //   let filtered = reservations.filter(
  //     (reservation) => reservation.archived !== false,
  //   );
  //   setFilteredReservations(filtered);
  // } else {
  //   let filtered = reservations.filter(
  //     (reservation) =>
  //       reservation.reservationStatus === key &&
  //       reservation.archived === false,
  //   );
  //   setFilteredReservations(filtered);
  // }
  // };

  const filterReservations = async (key) => {
    if (key === 'all') {
      let filtered = reservations.filter(
        (reservation) => reservation.archived === false,
      );
      setFilteredReservations(filtered);
    } else if (key === 'archived') {
      let filtered = reservations.filter(
        (reservation) => reservation.archived !== false,
      );
      setFilteredReservations(filtered);
    } else {
      let filtered = reservations.filter(
        (reservation) =>
          reservation.reservationStatus === key &&
          reservation.archived === false,
      );
      setFilteredReservations(filtered);
    }
  };

  useEffect(() => {
    dispatch(getAllReservations());
  }, [dispatch]);

  useEffect(() => {
    const fetchUsers = async () => {
      const userIds = [
        ...new Set(reservations.map((reservation) => reservation.userId)),
      ];

      for (const userId of userIds) {
        if (!users[userId]) {
          try {
            const action = await dispatch(getSpecificUser(userId));

            if (getSpecificUser.fulfilled.match(action)) {
              setUsers((prevUsers) => ({
                ...prevUsers,
                [userId]: action.payload,
              }));
            } else {
              console.error(
                `Failed to fetch user with ID ${userId}:`,
                action.payload,
              );
            }
          } catch (err) {
            console.error(`Error fetching user with ID ${userId}:`, err);
          }
        }
      }
    };

    const fetchEquipments = async () => {
      const equipIds = [
        ...new Set(reservations.map((reservation) => reservation.equipmentId)),
      ];

      for (const equipId of equipIds) {
        if (!equipments[equipId]) {
          try {
            const action = await dispatch(getSingleEquipment(equipId));

            if (getSingleEquipment.fulfilled.match(action)) {
              setEquipments((prevEquips) => ({
                ...prevEquips,
                [equipId]: action.payload,
              }));
            } else {
              console.error(
                `Failed to fetch equipment with ID ${equipId}:`,
                action.payload,
              );
            }
          } catch (err) {
            console.error(`Error fetching equipment with ID ${equipId}:`, err);
          }
        }
      }
    };

    const archiveOldReservations = async (reservations) => {
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

      const isWithinSixMonths = (endDate) => {
        const reservationDate = new Date(endDate);
        return reservationDate >= sixMonthsAgo;
      };

      for (const res of reservations) {
        if (!isWithinSixMonths(res.endDate) && res.archived === false) {
          await dispatch(updateReservation({ _id: res._id, archived: true }));
        }
      }
    };

    if (reservations.length > 0) {
      fetchUsers();
      fetchEquipments();
      setDownloadReservations(reservations);
      archiveOldReservations(reservations);
    }
    filterReservations(currentFilter);
  }, [dispatch, reservations]);

  //setting the downloadReservations state because the normal reservations array
  //only has ID's for equipments and names instead of their actual name
  useEffect(() => {
    const userIds = [
      ...new Set(reservations.map((reservation) => reservation.userId)),
    ];
    const equipIds = [
      ...new Set(reservations.map((reservation) => reservation.equipmentId)),
    ];
    //make sure all the users are equipment are fetched before trying to access them in the map
    if (
      (Object.entries(users).length !== userIds.length &&
        users.constructor === Object) ||
      (Object.entries(equipments).length !== equipIds.length &&
        equipments.constructor === Object) ||
      !downloadReservations
    ) {
      return;
    }
    setDownloadReservations((prevDownloadReservations) => {
      let temp = prevDownloadReservations.map((reservation) => {
        return {
          ...reservation,
          userId:
            (!users[reservation.userId]?.firstName
              ? 'NULL'
              : users[reservation.userId].firstName) +
            ' ' +
            (!users[reservation.userId]?.lastName
              ? 'NULL'
              : users[reservation.userId].lastName),
          equipmentId: !equipments[reservation.equipmentId]?.name
            ? 'NULL'
            : equipments[reservation.equipmentId].name,
        };
      });
      setDownloadReady(true);
      return temp;
    });
  }, [reservations, users, equipments]);

  const handleArchive = async () => {
    if (archiveRes !== null) {
      try {
        await dispatch(updateReservation({ _id: archiveRes, archived: true }));
      } catch (error) {
        toast.error('An unexpected error occurred. Please try again.');
      } finally {
        setDeleteReservationModal(false);
        setArchiveRes(null);
      }
    }
  };

  return (
    <div className="bg-sky-200 min-h-screen flex flex-col">
      <Navbar src={FarmShareBG} pageName="Reservation List" pageText="" />
      <ReservationFilter
        filterReservations={filterReservations}
        setCurrentFilter={setCurrentFilter}
        currentFilter={currentFilter}
      />

      {approveModal && (
        <ReservationModal
          setApproveModal={setApproveModal}
          setCancelModal={setCancelModal}
          setDenyModal={setDenyModal}
          selectedReservation={selectedReservation}
          mode={'Approve'}
          setSelectedReservation={setSelectedReservation}
        />
      )}

      {deleteReservationModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-md shadow-lg w-1/2 max-w-lg">
            <form>
              <label className="block mb-2 text-lg font-bold">
                Are You Sure You Want To{' '}
                <span className="text-red-500">Archive</span> This Reservation?
              </label>
              <div className="flex justify-end">
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md"
                  type="button"
                  onClick={() => {
                    handleArchive();
                    setDeleteReservationModal(false);
                  }}
                >
                  Confirm
                </button>
                <button
                  className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md"
                  onClick={() => setDeleteReservationModal(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {denyModal && (
        <ReservationModal
          setApproveModal={setApproveModal}
          setCancelModal={setCancelModal}
          setDenyModal={setDenyModal}
          mode={'Deny'}
          selectedReservation={selectedReservation}
          setSelectedReservation={setSelectedReservation}
        />
      )}

      {cancelModal && (
        <ReservationModal
          setApproveModal={setApproveModal}
          setCancelModal={setCancelModal}
          setDenyModal={setDenyModal}
          mode={'Cancel'}
          selectedReservation={selectedReservation}
          setSelectedReservation={setSelectedReservation}
        />
      )}

      {showReservationInfoModal && (
        <ReservationInfoModal
          setShowReservationInfoModal={setShowReservationInfoModal}
          setSelectedReservation={setSelectedReservation}
          reservation={selectedReservation}
          renterName={
            (users[selectedReservation.userId]?.firstName ?? '') +
            ' ' +
            (users[selectedReservation.userId]?.lastName ?? '')
          }
          equipmentName={equipments[selectedReservation.equipmentId].name}
        />
      )}

      <div className="flex-1 flex items-center py-5 px-[8vw] md:px-16 lg:px-[10vw]">
        {filteredReservations && filteredReservations.length === 0 && (
          <div className="flex flex-col items-center justify-center p-5 bg-gray-100 rounded-md shadow-md w-full">
            <span className="text-gray-700 text-lg">
              No reservations in our system right now!
            </span>
          </div>
        )}
        {filteredReservations &&
          !loading &&
          filteredReservations.length > 0 && (
            <div>
              <div className="w-full flex mb-4">
                {downloadReady ? (
                  <CSVLink
                    className="bg-[#2E7A32] mt-1 hover:bg-green-500 text-white font-bold py-2 px-4 rounded-full flex items-center space-x-2 transition duration-300"
                    data={downloadReservations}
                    target="_blank"
                    filename={'Farmshare-reservations.csv'}
                  >
                    <DwnldArrow width={20} height={20} />
                    <span>Download All Reservations</span>
                  </CSVLink>
                ) : (
                  <button
                    className="bg-gray-400 mt-1 text-white font-bold py-2 px-4 rounded-full flex items-center space-x-2 cursor-not-allowed"
                    disabled
                    aria-disabled="true"
                    title="No reservations available to download"
                  >
                    <DwnldArrow width={20} height={20} />
                    <span>Download All Reservations</span>
                  </button>
                )}
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full mx-auto">
                {filteredReservations.map((reservation) => {
                  const user = users[reservation.userId];
                  const name = user
                    ? `${user.firstName} ${user.lastName}`
                    : 'Loading...';
                  const equip = equipments[reservation.equipmentId];
                  const equipName = equip ? equip.name : 'Loading...';
                  return (
                    <ReservationCard
                      key={reservation._id}
                      startDate={reservation.startDate}
                      endDate={reservation.endDate}
                      status={reservation.reservationStatus}
                      equipmentName={equipName}
                      renterName={name}
                      setApproveModal={setApproveModal}
                      setCancelModal={setCancelModal}
                      setDenyModal={setDenyModal}
                      setDeleteModal={setDeleteReservationModal}
                      setArchiveRes={setArchiveRes}
                      resId={reservation._id}
                      reservation={reservation}
                      setSelectedReservation={setSelectedReservation}
                      setShowReservationInfoModal={setShowReservationInfoModal}
                    />
                  );
                })}
              </div>
            </div>
          )}

        {loading && (
          <div className="flex justify-center items-center w-full h-full">
            <p className="text-gray-700">Loading reservations...</p>
          </div>
        )}

        {error && !reservations && (
          <div className="flex justify-center items-center w-full h-full">
            <p className="text-red-600 font-bold">{error} - Please try again</p>
          </div>
        )}
      </div>
    </div>
  );
}
