export default function DeleteEquipmentModel({
  handleDelete,
  setDeleteModal,
  setViewEquipment,
  viewEquipment,
}) {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-md shadow-lg w-1/2 max-w-lg">
        <form onSubmit={handleDelete}>
          <label className="block mb-2 text-lg font-bold">
            Are You Sure You Want To{' '}
            <span className="text-red-600">Delete </span> This Equipment?{' '}
            <div className="text-center font-semibold my-4 text-lg">
              {viewEquipment.name}
            </div>
          </label>
          <div className="flex justify-end">
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md"
              type="submit"
            >
              Confirm
            </button>
            <button
              className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md"
              onClick={() => {
                setDeleteModal(false);
                setViewEquipment(null);
              }}
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
