import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EquipmentCard from './EquipmentCard';

import { getAllAvailableEquipment } from '../redux/slices/equipmentsSlice';

const EquipmentList = () => {
  const dispatch = useDispatch();

  const equipments = useSelector((state) => state.equipments.filteredEquipments);
  const loading = useSelector((state) => state.equipments.loading);

  useEffect(() => {
    dispatch(getAllAvailableEquipment());

  }, [dispatch]);


  return (
    <Fragment>
      {loading ? (
        <div>Loading....</div>
      ) : (
        <div className="container mx-auto mt-10 pb-10">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-6 justify-items-center">
            {console.log("this is equip",equipments)}
            {equipments.map((equip, index) => (
              <EquipmentCard key={index} equip={equip} />
            ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default EquipmentList;
