export default function UserInfoModal({
  setShowUserInfoModal,
  setSelectedUser,
  user,
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-xl w-11/12 max-w-md p-6">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          User Information
        </h2>
        <div className="space-y-2">
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">First Name:</span>
            <span className="text-gray-900">
              {user.firstName ? user.firstName : 'NOT REGISTERED'}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Last Name:</span>
            <span className="text-gray-900">
              {user.lastName ? user.lastName : 'NOT REGISTERED'}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Email:</span>
            <span className="text-gray-900">
              {user.email ? user.email : 'Not Provided'}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Phone:</span>
            <span className="text-gray-900">
              {user.phoneNumber ? user.phoneNumber : 'Not Provided'}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Address:</span>
            <span className="text-gray-900">
              {user.address ? user.address : 'Not Provided'}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Role:</span>
            <span className="text-gray-900">{user.role}</span>
          </div>
        </div>
        <div className="mt-6 flex justify-end">
          <button
            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-5 rounded-md transition duration-200"
            onClick={() => {
              setShowUserInfoModal(false);
              setSelectedUser(null);
            }}
            type="button"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
