export default function ReservationFilter({
  filterReservations,
  setCurrentFilter,
  currentFilter,
}) {
  return (
    <div className="flex justify-between items-center px-[8vw] md:px-16 lg:px-[10vw] p-4 bg-white shadow-md rounded-lg border-b-2">
      <button
        onClick={() => {
          filterReservations('all');
          setCurrentFilter('all');
        }}
        className={`px-4 py-2 text-sm font-semibold rounded-full transition-all duration-300 ${
          currentFilter === 'all'
            ? 'bg-[#2E7A32] text-white shadow-md'
            : 'bg-gray-300 hover:bg-gray-400 text-black'
        }`}
      >
        All
      </button>
      <button
        onClick={() => {
          filterReservations('pending');
          setCurrentFilter('pending');
        }}
        className={`px-4 py-2 text-sm font-semibold rounded-full transition-all duration-300 ${
          currentFilter === 'pending'
            ? 'bg-[#2E7A32] text-white shadow-md'
            : 'bg-gray-300 hover:bg-gray-400 text-black'
        }`}
      >
        Pending
      </button>
      <button
        onClick={() => {
          filterReservations('approved');
          setCurrentFilter('approved');
        }}
        className={`px-4 py-2 text-sm font-semibold rounded-full transition-all duration-300 ${
          currentFilter === 'approved'
            ? 'bg-[#2E7A32] text-white shadow-md'
            : 'bg-gray-300 hover:bg-gray-400 text-black'
        }`}
      >
        Approved
      </button>
      <button
        onClick={() => {
          filterReservations('filled');
          setCurrentFilter('filled');
        }}
        className={`px-4 py-2 text-sm font-semibold rounded-full transition-all duration-300 ${
          currentFilter === 'filled'
            ? 'bg-[#2E7A32] text-white shadow-md'
            : 'bg-gray-300 hover:bg-gray-400 text-black'
        }`}
      >
        Filled
      </button>
      <button
        onClick={() => {
          filterReservations('canceled');
          setCurrentFilter('canceled');
        }}
        className={`px-4 py-2 text-sm font-semibold rounded-full transition-all duration-300 ${
          currentFilter === 'canceled'
            ? 'bg-[#2E7A32] text-white shadow-md'
            : 'bg-gray-300 hover:bg-gray-400 text-black'
        }`}
      >
        Canceled
      </button>
      <button
        onClick={() => {
          filterReservations('archived');
          setCurrentFilter('archived');
        }}
        className={`px-4 py-2 text-sm font-semibold rounded-full transition-all duration-300 ${
          currentFilter === 'archived'
            ? 'bg-[#2E7A32] text-white shadow-md'
            : 'bg-gray-300 hover:bg-gray-400 text-black'
        }`}
      >
        Archived
      </button>
    </div>
  );
}
