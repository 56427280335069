import React, { Fragment, useEffect, useState } from 'react';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { getCurrentUser } from './redux/slices/myselfSlice';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './components/Navbar';
import NavbarLoading from './components/NavbarLoading';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ErrorPage from './pages/ErrorPage';
import HomePage from './pages/HomePage';
import Footer from './components/Footer';
import FarmsharePage from './pages/FarmsharePage';
import FarmsharePageInProgress from './pages/FarmsharePageInProgress';
import AchievementsPage from './pages/AchievementsPage';
import AboutUsPage from './pages/AboutUsPage';
import RCDPage from './pages/RCDPage';
import ContactUsPage from './pages/ContactUsPage';
import EventPage from './pages/EventPage';
import ResourcesPage from './pages/ResourcesPage';
import PrivacyPage from './pages/PrivacyPage';
import DetailEquipmentPage from './pages/DetailEquipmentPage';
import HomePageBG from './assets/HomepageBG.jpg';
import UserList from './pages/UserList';
import ReservationList from './pages/ReservationList';
import NewUserPage from './pages/NewUserPage';
import UserProfilePage from './pages/UserProfilePage';

import UserReservations from './pages/UserReservations';
import { MoonLoader } from 'react-spinners';
import EquipmentListPage from './pages/EquipmentListPage';


function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  const user = useSelector((state) => state.myself.user);
  const isAuth = useSelector((state) => state.myself.isAuthenticated);
  const newUser = useSelector((state) => state.myself.newUser);
  const loading = useSelector((state) => state.myself.fetchingUser);


  if (loading) {
    return (
      <div>
        <NavbarLoading
          src={HomePageBG}
          pageName={'Sustainable Land Initiative'}
        />
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
          <MoonLoader color="#000" size={50} />
        </div>
      </div>
    );
  }

  // const isAdmin = true;
  const isAdmin = user?.role === 'admin';

  //const isAdmin = user?.role === 'admin';

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/events" element={<EventPage />} />
        <Route
          path="/farmshare" 
          element={newUser ? <NewUserPage /> : <FarmsharePage />}
        />
        <Route
          path="/farmshare/:equipmentId"
          element={<DetailEquipmentPage />}
        />
        <Route
          path="/farmshare/profile"
          element={isAuth ? <UserProfilePage /> : <Navigate to="/" />}
        />
        <Route path="/farmshareIP" element={<FarmsharePageInProgress />} />
        <Route path="/achievements" element={<AchievementsPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/rcd" element={<RCDPage />} />
        <Route path="/resources" element={<ResourcesPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route
          path="/userlist"
          element={isAdmin ? <UserList /> : <Navigate to="/" />}
        />
        <Route
          path="/reservationlist"
          element={isAdmin ? <ReservationList /> : <Navigate to="/" />}
        />
        <Route
          path="/myreservations"
          element={user?.email ? <UserReservations /> : <Navigate to="/" />}
        />
        <Route
          path="/equipmentlist"
          element={isAdmin ? <EquipmentListPage /> : <HomePage />}
        />
        <Route
          path="*"
          element={
            <Fragment>
              <Navbar src={HomePageBG} pageName={``} />
              <ErrorPage />
            </Fragment>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
