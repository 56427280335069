import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import loginImg from '../assets/login-signup.jpg';
import logo from '../assets/Logo.svg';
import Input from '../components/Input';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { loginRequest } from '../redux/slices/authSlice';
import { getCurrentUser } from '../redux/slices/myselfSlice';

const Login = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(loginRequest({ email, password }));
  };

  if (isAuthenticated) {
    dispatch(getCurrentUser());
    navigate('/');
  }

  return (
    <Fragment>
      <ToastContainer />
      <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full">
        <div className="hidden sm:block">
          <img className="w-full h-full object-cover" src={loginImg} alt="" />
        </div>

        <div className="bg-gray-100 flex flex-col justify-center">
          <form
            onSubmit={handleLogin}
            className="max-w-[450px] w-full mx-auto bg-white px-8 py-5 shadow-md"
          >
            <Link to="/">
              <img src={logo} className="w-[60%] mx-auto"/>
            </Link>
            <Input
              name="username"
              type="text"
              label="Username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              name="password"
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            <button
              type="submit"
              className="border w-full mt-2 mb-4 py-3 bg-lime-700 hover:bg-lime-800 text-white"
            >
              Sign In
            </button>

            <div className="flex justify-between">
              <p className="flex items-center">
                <input className="mr-2" type="checkbox" /> Remember Me
              </p>
              <Link to="/signup" className="underline">Create an account</Link>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
