import UserInfoModal from './UserInfoModal';
import { useState, useEffect } from 'react';
import { ReactComponent as UpArrow } from '../assets/up-arrow.svg';
import { ReactComponent as DownArrow } from '../assets/down-arrow.svg';
import { ReactComponent as DwnldArrow } from '../assets/download-arrow.svg';
import { CSVDownload, CSVLink } from 'react-csv';

export default function UserTable({
  sortedUsers,
  setDeleteModal,
  setUpdateModal,
  setChangeUser,
  loading,
  setAddUserModal,
}) {
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState(sortedUsers);

  useEffect(() => {
    setUsers(sortedUsers);
  }, [sortedUsers]);

  const roleOrder = { admin: 1, user: 2 };

  const sortUsers = (key, ord) => {
    setUsers((prevUsers) => {
      const usersCopy = [...prevUsers];
      usersCopy.sort((a, b) => {
        let comparison = 0;

        switch (key) {
          case 'First':
            if (!a.firstName) {
              comparison = false;
            } else if (!b.firstName || (!b.firstName && !a.firstName)) {
              comparison = true;
            } else {
              comparison = a.firstName.localeCompare(b.firstName);
            }
            break;
          case 'Last':
            if (!a.lastName) {
              comparison = false;
            } else if (!b.lastName || (!b.lastName && !a.lastName)) {
              comparison = true;
            } else {
              comparison = a.lastName.localeCompare(b.lastName);
            }
            break;
          case 'Email':
            comparison = a.email.localeCompare(b.email);
            break;
          case 'Role':
            comparison = roleOrder[a.role] - roleOrder[b.role];
            break;
          default:
            break;
        }

        if (comparison === 0 && key !== 'Role') {
          comparison = a.email.localeCompare(b.email);
        }

        return ord === 'asc' ? comparison : -comparison;
      });

      return usersCopy;
    });
  };

  return (
    <div className="overflow-x-auto flex flex-col gap-5">
      <div className="flex space-x-4 mt-5 ml-5">
        <button
          className="bg-[#2E7A32] hover:bg-green-500 text-white font-bold py-2 px-4 rounded-full"
          onClick={() => setAddUserModal(true)}
        >
          Add User +
        </button>

        <CSVLink
          className="bg-[#2E7A32] hover:bg-green-500 text-white font-bold py-2 px-4 rounded-full flex items-center space-x-2"
          data={sortedUsers}
          target="_blank"
          filename={'Farmshare-users.csv'}
        >
          <DwnldArrow width={20} height={20}/>
          <span>Download User Information</span>
        </CSVLink>
      </div>
      {showUserInfoModal && (
        <UserInfoModal
          setShowUserInfoModal={setShowUserInfoModal}
          setSelectedUser={setSelectedUser}
          user={selectedUser}
        />
      )}
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            {/* First Name Header */}
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              <div className="flex items-center justify-between">
                <span>First Name</span>
                <div className="flex space-x-2">
                  <UpArrow
                    width={15}
                    height={15}
                    onClick={() => sortUsers('First', 'asc')}
                    className="hover:cursor-pointer"
                  />
                  <DownArrow
                    width={15}
                    height={15}
                    onClick={() => sortUsers('First', 'desc')}
                    className="hover:cursor-pointer"
                  />
                </div>
              </div>
            </th>

            {/* Last Name Header */}
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              <div className="flex items-center justify-between">
                <span>Last Name</span>
                <div className="flex space-x-2">
                  <UpArrow
                    width={15}
                    height={15}
                    onClick={() => sortUsers('Last', 'asc')}
                    className="hover:cursor-pointer"
                  />
                  <DownArrow
                    width={15}
                    height={15}
                    onClick={() => sortUsers('Last', 'desc')}
                    className="hover:cursor-pointer"
                  />
                </div>
              </div>
            </th>

            {/* Email Header */}
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              <div className="flex items-center justify-between">
                <span>Email</span>
                <div className="flex space-x-2">
                  <UpArrow
                    width={15}
                    height={15}
                    onClick={() => sortUsers('Email', 'asc')}
                    className="hover:cursor-pointer"
                  />
                  <DownArrow
                    width={15}
                    height={15}
                    onClick={() => sortUsers('Email', 'desc')}
                    className="hover:cursor-pointer"
                  />
                </div>
              </div>
            </th>

            {/* Account Type Header */}
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              <div className="flex items-center justify-between">
                <span>Account Type</span>
                <div className="flex space-x-2">
                  <UpArrow
                    width={15}
                    height={15}
                    onClick={() => sortUsers('Role', 'asc')}
                    className="hover:cursor-pointer"
                  />
                  <DownArrow
                    width={15}
                    height={15}
                    onClick={() => sortUsers('Role', 'desc')}
                    className="hover:cursor-pointer"
                  />
                </div>
              </div>
            </th>

            {/* Options Header */}
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              Options
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5" className="text-center py-4">
                Loading...
              </td>
            </tr>
          ) : (
            users.map((user) => (
              <tr
                key={user.email}
                className="bg-white hover:bg-gray-100 hover:cursor-pointer"
                onClick={() => {
                  setSelectedUser(user);
                  setShowUserInfoModal(true);
                }}
              >
                <td className="border border-gray-300 px-4 py-2">
                  {user.firstName ? user.firstName : 'NOT REGISTERED'}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {user.lastName ? user.lastName : 'NOT REGISTERED'}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {user.email ? user.email : 'Not Provided'}
                </td>
                <td className="border border-gray-300 px-4 py-2 capitalize">
                  {user.role}
                </td>
                <td className="border border-gray-300 px-4 py-2 capitalize">
                  <div className="flex justify-around">
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded-md"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the row's onClick
                        setUpdateModal(true);
                        setChangeUser(user._id);
                      }}
                    >
                      Update Role
                    </button>
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded-md"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the row's onClick
                        setDeleteModal(true);
                        setChangeUser(user._id);
                      }}
                    >
                      Delete User
                    </button>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
