import FarmShareBG from '../assets/FarmshareBG.jpg';
import Navbar from '../components/Navbar';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllEquipment,
  deleteEquipment,
  updateEquipment
} from '../redux/slices/equipmentsSlice';
import { useEffect, useState } from 'react';
import EquipmentTable from '../components/EquipmentTable';
import EquipmentContentModal from '../components/EquipmentContentModal';
import DeleteEquipmentModel from '../components/DeleteEquipmentModal';
import AddEquipmentModal from '../components/AddEquipmentModal';
import UpdateEquipmentModal from '../components/UpdateEquipmentModal';


export default function EquipmentListPage() {
  const dispatch = useDispatch();
  const equipments = useSelector((state) => state.equipments.equipments);
  const loading = useSelector((state) => state.equipments.loading);
  const error_message = useSelector((state) => state.equipments.error);
  const [updateEquipmentModal, setUpdateEquipmentModal] = useState(false);
  const [deleteEquipmentModal, setDeleteEquipmentModal] = useState(false);
  const [addEquipmentModal, setAddEquipmentModal] = useState(false);
  const [viewContentModal, setViewContentModal] = useState(false);
  const [viewEquipment, setViewEquipment] = useState(null);
 

  useEffect(() => {
    dispatch(getAllEquipment());
  }, [dispatch]);

  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(updateEquipment( viewEquipment._id));
    setUpdateEquipmentModal(false);
    setViewEquipment(null);
  };


  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(deleteEquipment(viewEquipment._id));
    setDeleteEquipmentModal(false);
    setViewEquipment(null);
  };

  return (
    <div className="bg-sky-200">
      <Navbar src={FarmShareBG} pageName={`Equipment List`} pageText={''} />
      {/* {updateEquipmentModal && (
        <UpdateEquipmentModal
          setUpdateModal={setUpdateEquipmentModal}
          setViewEquipment={setViewEquipment}
          handleUpdate={handleUpdate}
          viewEquipment={viewEquipment}
        />
      )} */}
      {/* Render UpdateEquipmentModal */}
      {updateEquipmentModal && (
        <UpdateEquipmentModal
          handleUpdate={handleUpdate}
          setUpdateModal={setUpdateEquipmentModal}
          viewEquipment={viewEquipment}
          
        />
      )}

      {addEquipmentModal && <AddEquipmentModal 
      setAddEquipmentModal={setAddEquipmentModal} />}

      {deleteEquipmentModal && (
        <DeleteEquipmentModel
          handleDelete={handleDelete}
          setDeleteModal={setDeleteEquipmentModal}
          setViewEquipment={setViewEquipment}
          viewEquipment={viewEquipment}
        />
      )}
      {viewContentModal && (
        <EquipmentContentModal
          setViewContentModal={setViewContentModal}
          viewEquipment={viewEquipment}
        />
      )}
      {!error_message && (
        <EquipmentTable
          equipments={equipments}
          setDeleteModal={setDeleteEquipmentModal}
          setUpdateModal={setUpdateEquipmentModal}
          setViewContentModal={setViewContentModal}
          loading={loading}
          setViewEquipment={setViewEquipment}
          setAddEquipmentModal={setAddEquipmentModal}
        />
      )}
      {error_message && (
        <div className="flex justify-center">
          <p className="text-red-600 font-bold">
            {error_message} - Please Try Again
          </p>
        </div>
      )}
    </div>
  );
}
