import { useState } from 'react';
import { CalendarDialog } from './CalendarDialog';
import { createReservation } from '../redux/slices/reservationSlice';
import { useDispatch } from 'react-redux';

export default function CreateReservationModal({
  setShowCreateReservationModal,
  renterName,
  equipment,
  quantity,
  userId,
}) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    deliveryAssistance: '',
    pickupAssistance: '',
    startDate: '',
    endDate: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateRangeChange = (range) => {
    setFormData({
      ...formData,
      startDate: range.from,
      endDate: range.to,
    });
  };

  const handleCreateReservation = async (e) => {
    e.preventDefault();
    console.log('Creating reservation:', formData);
    dispatch(
      createReservation({
        userId: userId,
        equipmentId: equipment._id,
        qty: quantity,
        rentalPrice: equipment.rentalPrice * quantity,
        startDate: formData.startDate,
        endDate: formData.endDate,
        deliveryAssistance: formData.deliveryAssistance,
        pickupAssistance: formData.pickupAssistance,
      }),
    ).then(() => setShowCreateReservationModal(false));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-xl w-11/12 max-w-md p-6">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          Reservation Information
        </h2>
        <div className="space-y-2">
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Renter Name:</span>
            <span className="text-gray-900">{renterName}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Equipment:</span>
            <span className="text-gray-900">{equipment.name}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Location:</span>
            <span className="text-gray-900">{equipment.location}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Quantity:</span>
            <span className="text-gray-900">{quantity}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Price:</span>
            <span className="text-gray-900">
              ${equipment.rentalPrice * quantity}/day
            </span>
          </div>
          <div className="flex justify-center mt-4">
            <CalendarDialog
              value={formData.dateRange}
              onChange={handleDateRangeChange}
            />
          </div>
          <form onSubmit={handleCreateReservation}>
            <div className="flex items-center justify-between gap-4 my-3">
              <div className="flex items-center gap-2">
                <span className="font-medium text-gray-700">Start Date:</span>
                <span className="text-gray-900 font-bold">
                  {formData.startDate
                    ? new Date(formData.startDate).toLocaleDateString()
                    : 'N/A'}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="font-medium text-gray-700">End Date:</span>
                <span className="text-gray-900 font-bold">
                  {formData.endDate
                    ? new Date(formData.endDate).toLocaleDateString()
                    : 'N/A'}
                </span>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <label className="font-medium text-gray-700">
                Delivery Assistance?
              </label>
              <div className="flex space-x-4">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="deliveryAssistance"
                    value="yes"
                    checked={formData.deliveryAssistance === 'yes'}
                    onChange={handleInputChange}
                    className="form-radio"
                  />
                  <span className="text-gray-900">Yes</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="deliveryAssistance"
                    value="no"
                    checked={formData.deliveryAssistance === 'no'}
                    onChange={handleInputChange}
                    className="form-radio"
                  />
                  <span className="text-gray-900">No</span>
                </label>
              </div>
            </div>
            <div className="flex justify-between items-center mt-4">
              <label className="font-medium text-gray-700">
                Pickup Assistance?
              </label>
              <div className="flex space-x-4">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="pickupAssistance"
                    value="yes"
                    checked={formData.pickupAssistance === 'yes'}
                    onChange={handleInputChange}
                    className="form-radio"
                  />
                  <span className="text-gray-900">Yes</span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="pickupAssistance"
                    value="no"
                    checked={formData.pickupAssistance === 'no'}
                    onChange={handleInputChange}
                    className="form-radio"
                  />
                  <span className="text-gray-900">No</span>
                </label>
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-5 rounded-md transition duration-200"
                onClick={() => {
                  setShowCreateReservationModal(false);
                }}
                type="button"
              >
                Close
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-5 ml-4 rounded-md transition duration-200"
                type="sumbit"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
