import FarmShareBG from '../assets/FarmshareBG.jpg';
import Navbar from '../components/Navbar';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import ReservationFilter from '../components/ReservationFilter';
import UserReservationCard from '../components/UserReservationCard';
import UserCancelReservationModal from '../components/UserCancelReservationModal';
import { getSingleEquipment } from '../redux/slices/equipmentsSlice';
import { getMyReservations } from '../redux/slices/myselfSlice';
import { deleteMyReservation } from '../redux/slices/myselfSlice';
import { toast } from 'react-toastify';

export default function UserReservations() {
  const reservations = useSelector((state) => state.myself.reservations);
  const loading = useSelector((state) => state.myself.loading);
  const error = useSelector((state) => state.reservations.error);
  const [currentFilter, setCurrentFilter] = useState('all');
  const [filteredReservations, setFilteredReservations] =
    useState(reservations);
  const [cancelModal, setCancelModal] = useState(false);
  const [equipments, setEquipments] = useState({});
  const [selectedReservation, setSelectedReservation] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyReservations());
  }, [dispatch]);

  const filterReservations = (key) => {
    if (key === 'all') {
      setFilteredReservations(reservations);
    } else {
      let filtered = reservations.filter(
        (reservation) => reservation.reservationStatus === key,
      );
      setFilteredReservations(filtered);
    }
  };

  useEffect(() => {
    const fetchEquipments = async () => {
      const equipIds = [
        ...new Set(reservations.map((reservation) => reservation.equipmentId)),
      ];

      for (const equipId of equipIds) {
        if (!equipments[equipId]) {
          try {
            const action = await dispatch(getSingleEquipment(equipId));

            if (getSingleEquipment.fulfilled.match(action)) {
              setEquipments((prevEquips) => ({
                ...prevEquips,
                [equipId]: action.payload,
              }));
            } else {
              console.error(
                `Failed to fetch equipment with ID ${equipId}:`,
                action.payload,
              );
            }
          } catch (err) {
            console.error(`Error fetching equipment with ID ${equipId}:`, err);
          }
        }
      }
    };

    if(equipments && Object.keys(equipments).length === 0){
      fetchEquipments();
    }
    filterReservations(currentFilter);
  }, [dispatch, reservations]);


  return (
    <div className="bg-sky-200 min-h-screen flex flex-col">
      <Navbar src={FarmShareBG} pageName="Reservation List" pageText="" />
      <ReservationFilter
        filterReservations={filterReservations}
        setCurrentFilter={setCurrentFilter}
        currentFilter={currentFilter}
      />

      {cancelModal && (
        <UserCancelReservationModal
          setCancelModal={setCancelModal}
          selectedReservation={selectedReservation}
          setSelectedReservation={setSelectedReservation}
        />
      )}

      <div className="flex-1 flex flex-col items-center">
        {filteredReservations && !loading && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full max-w-6xl mx-auto p-4">
            {filteredReservations.map((reservation) => {
              const equip = equipments[reservation.equipmentId];
              const equipName = equip ? equip.name : 'Loading...';
              return (
                <UserReservationCard
                  key={reservation._id}
                  startDate={reservation.startDate}
                  endDate={reservation.endDate}
                  status={reservation.reservationStatus}
                  equipmentName={equipName}
                  setCancelModal={setCancelModal}
                  // setDeleteModal={setDeleteReservationModal}
                  // setDeleteRes={setDeleteRes}
                  resId={reservation._id}
                  reservation={reservation}
                  setSelectedReservation={setSelectedReservation}
                />
              );
            })}
          </div>
        )}

        {loading && reservations.length === 0 && (
          <div className="flex justify-center items-center w-full h-full">
            <p className="text-gray-700">Loading reservations...</p>
          </div>
        )}

        {error && !reservations && (
          <div className="flex justify-center items-center w-full h-full">
            <p className="text-red-600 font-bold">{error} - Please try again</p>
          </div>
        )}
      </div>
    </div>
  );
}
