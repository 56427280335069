import React from 'react';

import Logo from '../assets/Logo.svg';
import { AiOutlineMenu } from 'react-icons/ai';
import { ToastContainer } from 'react-toastify';

const NavbarLoading = ({ src, pageName, pageText }) => {
  return (
    <>
      <ToastContainer />
      <div className="h-[2rem] w-auto bg-[#2E7A32] relative">
        <div className="p-1 text-white"></div>
      </div>
      <div
        style={{ color: '#FFEB83' }}
        className="font-Merriweather-Sans font-extrabold text-shadow-lg text-4xl max-w-[70vw] md:max-w-[80vw] md:text-5xl left-[8vw] md:left-16 lg:left-[10vw] top-[80px] md:top-[180px] lg:top-[250px] lg:text-6xl absolute z-20"
      >
        {pageName}
        <div className="font-Merriweather-Sans text-lg md:flex w-auto h-auto mt-3  md:max-w-[70vw] md:text-2xl font-bold">
          {pageText}
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${src})` }}
        className={`flex items-start min-h-[350px] md:min-h-[400px] lg:min-h-[500px] text-[#2E7A32] text-xs sm:text-md md:text-lg lg:text-lg xl:text-xl 3xl:text-2xl bg-cover bg-no-repeat bg-center relative pl-31 pt-14`}
      >
        <div className="pb-80 mr-8 xl:block">
          <img
            className="md:flex w-[100px] h-auto ml-[10vw]"
            src={Logo}
            alt="/"
          />
        </div>
        <div>
          <ul className="hidden mt-5 md:flex font-Merriweather-Sans text-center ml-[8vw] md:ml-16 lg:ml-[10vw] xl:ml-0">
            <li className="mx-2 px-6 py-2 bg-[#A8C356] min-w-[9vw] rounded-full flex justify-center shadow">
              Home
            </li>
            <li className="mx-2 px-6 py-2 bg-[#A8C356] min-w-[9vw] rounded-full flex justify-center shadow">
              About SLI
            </li>
            <li className="mx-2 px-6 py-2 bg-[#A8C356] min-w-[9vw] rounded-full flex justify-center shadow">
              Farmshare
            </li>
            <li className="mx-2 px-6 py-2 bg-[#A8C356] min-w-[9vw] rounded-full flex justify-center shadow">
              Events
            </li>
            <li className="mx-2 px-6 py-2 bg-[#A8C356] min-w-[9vw] rounded-full flex justify-center shadow">
              Resources
            </li>
          </ul>
        </div>
        <div className="block md:hidden rounded text-[#FFEB83] font-Merriweather-Sans absolute bg-[#2E7A32] right-7 md:right-14 top-12">
          <AiOutlineMenu className="p-1" size={30} />
        </div>
        <ul className="hidden fixed left-0 top-0 w-[60%] h-full border-r border-r-[#A8C356] bg-[#A8C356] ease-in-out duration-500 z-20">
          <div className="cursor-pointer absolute right-5 top-5"></div>

          <h1 className="w-auto text-3xl font-bold text-[#2E7A32] m-4">SLI</h1>
          <li className="p-4 border-b border-[#2E7A32]">Home</li>
          <li className="p-4 border-b border-[#2E7A32]">About SLI</li>
          <li className="p-4 border-b border-[#2E7A32]">Farmshare</li>
          <li className="p-4 border-b border-[#2E7A32]">Events</li>
          <li className="p-4 border-b border-[#2E7A32]">Resources</li>
          <li className="p-4">Enroll</li>
        </ul>
      </div>
    </>
  );
};
export default NavbarLoading;
