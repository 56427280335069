export default function EquipmentTable({
  equipments,
  setDeleteModal,
  setUpdateModal,
  setChangeEquipment,
  loading,
  setAddEquipmentModal,
  setViewContentModal,
  setViewEquipment,
}) 
{
  return (
    
    <div className="overflow-x-auto flex flex-col gap-5">
      <button
        className="bg-[#2E7A32] hover:bg-green-500 text-white font-bold py-1
         px-3 rounded-full self-start mt-5 ml-5"
        onClick={() => setAddEquipmentModal(true)}
      >
        Add Equipment +
      </button>
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              Name
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              Quantity
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              Rental Price
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              Location
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              Content
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left text-[#2E7A32] font-bold">
              Options
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="4" className="text-center py-4">
                Loading...
              </td>
            </tr>
          ) : (
            
            equipments.map((equip) => (
      
              <tr key={equip.email} className="bg-white hover:bg-gray-100">
                <td className="border border-gray-300 px-4 py-2 flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <img
                      src={equip.images[0]}
                      alt={equip.name}
                      
                      className="w-24 h-24 object-cover"
                    />
                  </div>
                  <div className="space-y-2">
                    <div className="font-semibold">{equip.name}</div>
                    <div className="text-gray-500 text-sm capitalize">
                      Type: {equip.type}
                    </div>
                    <div
                      className={`text-sm font-bold ${
                        equip.availability ? 'text-green-500' : 'text-red-500'
                      }`}
                    >
                      {equip.availability ? 'Available' : 'Unavailable'}
                    </div>
                  </div>
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {equip.qty}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  ${equip.rentalPrice}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {equip.location}
                </td>
                <td className="border border-gray-300 px-4 py-2 capitalize">
                  <div className="flex justify-around">
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded-md"
                      onClick={() => {
                        setViewContentModal(true);
                        setViewEquipment(equip);
                      }}
                    >
                      View
                    </button>
                  </div>
                </td>
                <td className="border border-gray-300 px-4 py-2 capitalize">
                  <div className="flex justify-around">
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded-md"
                      onClick={() => {
                        setUpdateModal(true);
                        setViewEquipment(equip);
                      }}
                    >
                      Update
                    </button>
                    <button
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded-md"
                      onClick={() => {
                        setDeleteModal(true);
                        setViewEquipment(equip);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
