import { useState } from 'react';

export default function EquipmentContentModal({
  setViewContentModal,
  viewEquipment,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Helper functions for navigation
  const showPreviousImage = () => {
    if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
  };

  const showNextImage = () => {
    if (currentIndex < viewEquipment.images.length - 1)
      setCurrentIndex(currentIndex + 1);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-md shadow-lg w-2/3 max-w-3xl  overflow-y-auto">
        <div className="relative w-full h-64 flex items-center justify-center mb-4">
          {/* Left Navigation Button */}
          <button
            className={`absolute left-1 bg-gray-500 hover:bg-gray-700 text-white font-bold p-2 rounded-lg ${
              currentIndex === 0 && 'opacity-50 cursor-not-allowed'
            }`}
            onClick={showPreviousImage}
            disabled={currentIndex === 0}
          >
            {/* Chevron Left SVG */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>

          {/* Image */}
          <img
            src={viewEquipment.images[currentIndex]}
            alt={`Equipment image ${currentIndex + 1}`}
            className="max-w-full max-h-full rounded-md"
          />

          {/* Right Navigation Button */}
          <button
            className={`absolute right-1 bg-gray-500 hover:bg-gray-700 text-white font-bold p-2 rounded-lg ${
              currentIndex === viewEquipment.images.length - 1 &&
              'opacity-50 cursor-not-allowed'
            }`}
            onClick={showNextImage}
            disabled={currentIndex === viewEquipment.images.length - 1}
          >
            {/* Chevron Right SVG */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>

        <div className="mt-4">
          <div className="font-semibold">Tutorials</div>
          <p>{viewEquipment.tutorials}</p>
        </div>
        <div className="mt-4">
          <div className="font-semibold">Specs</div>
          <p>{viewEquipment.specs}</p>
        </div>
        <div className="mt-4">
          <div className="font-semibold">Description</div>
          <p>{viewEquipment.description}</p>
        </div>
        <div className="flex justify-end">
          <button
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md"
            onClick={() => {
              setViewContentModal(false);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
