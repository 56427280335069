import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateEquipment } from '../redux/slices/equipmentsSlice';
import { toast } from 'react-toastify';

const UpdateEquipmentModal = ({ setUpdateModal, viewEquipment }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [qty, setQty] = useState('');
  const [rentalPrice, setRentalPrice] = useState('');
  const [location, setLocation] = useState('');
  const [type, setType] = useState('');
  const [images, setImages] = useState([]);
  const [imageInput, setImageInput] = useState('');
  const [specs, setSpecs] = useState('');
  const [description, setDescription] = useState('');
  const [tutorials, setTutorials] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Populate form with current equipment data
  useEffect(() => {
    console.log("Current Equipment:", viewEquipment);
    if (viewEquipment) {
        setName(viewEquipment.name || '');
        setQty(viewEquipment.qty || '');
        setRentalPrice(viewEquipment.rentalPrice || '');
        setLocation(viewEquipment.location || '');
        setType(viewEquipment.type || '');
        setImages(viewEquipment.images || []);
        setSpecs(viewEquipment.specs || '');
        setDescription(viewEquipment.description || '');
        setTutorials(viewEquipment.tutorials || '');
    }
  }, [viewEquipment]);

  const handleAddImage = () => {
    if (imageInput.trim()) {
      setImages((prevImages) => [...prevImages, imageInput.trim()]);
      setImageInput('');
    }
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const parsedQty = parseInt(qty, 10);
    const parsedRentalPrice = parseFloat(rentalPrice);

    if (isNaN(parsedQty) || isNaN(parsedRentalPrice)) {
      alert('Please enter valid numbers for quantity and rental price.');
      return;
    }

    setIsLoading(true);

    try {
    console.log("this is current equip ", viewEquipment._id)
      await dispatch(
        updateEquipment({
          id: viewEquipment._id,
          name,
          qty: parsedQty,
          rentalPrice: parsedRentalPrice,
          location,
          type,
          images,
          specs,
          description,
          tutorials,
        })
      ).unwrap();

      toast.success('Equipment updated successfully!');
      setUpdateModal(false);
    } catch (error) {
      toast.error('Failed to update equipment: ' + error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-1/3 h-3/4 overflow-hidden flex flex-col">
        <h3 className="text-lg font-bold mb-4">Update Equipment</h3>
        <div className="overflow-y-auto flex-grow pr-4">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block font-semibold" htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label className="block font-semibold" htmlFor="qty">Quantity</label>
              <input
                type="number"
                id="qty"
                value={qty}
                onChange={(e) => setQty(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label className="block font-semibold" htmlFor="rentalPrice">Rental Price</label>
              <input
                type="number"
                id="rentalPrice"
                value={rentalPrice}
                onChange={(e) => setRentalPrice(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label className="block font-semibold" htmlFor="location">Location</label>
              <input
                type="text"
                id="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label className="block font-semibold" htmlFor="type">Type</label>
              <input
                type="text"
                id="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label className="block font-semibold" htmlFor="images">Image URLs</label>
              <div className="flex gap-2 mb-2">
                <input
                  type="text"
                  id="imageInput"
                  value={imageInput}
                  onChange={(e) => setImageInput(e.target.value)}
                  className="flex-grow p-2 border border-gray-300 rounded-md"
                  placeholder="Enter image URL"
                />
                <button
                  type="button"
                  onClick={handleAddImage}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded-md"
                >
                  Add
                </button>
              </div>
              <div className="mt-2 flex flex-wrap gap-2">
                {images.map((image, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <img
                      src={image}
                      alt={`Preview ${index}`}
                      className="w-16 h-16 object-cover rounded-md"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveImage(index)}
                      className="text-red-500 text-xs mt-1"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <label className="block font-semibold" htmlFor="specs">Specifications</label>
              <textarea
                id="specs"
                value={specs}
                onChange={(e) => setSpecs(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
                rows="2"
                required
              />
            </div>
            <div>
              <label className="block font-semibold" htmlFor="description">Description</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
                rows="3"
                required
              />
            </div>
            <div>
              <label className="block font-semibold" htmlFor="tutorials">Tutorials</label>
              <textarea
                id="tutorials"
                value={tutorials}
                onChange={(e) => setTutorials(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
                rows="2"
                required
              />
            </div>
          </form>
        </div>
        <div className="flex justify-between mt-4">
          <button
            type="button"
            onClick={() => setUpdateModal(false)}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md"
            disabled={isLoading}
          >
            {isLoading ? 'Updating...' : 'Update Equipment'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateEquipmentModal;
