import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Navbar from '../components/Navbar';
import HomePageBG from '../assets/HomepageBG.jpg';
import ContentBox from '../components/ContentBox';
import PictureContentLeft from '../components/PictureContentLeft';
import SliProcessContent from '../assets/SLI_process.svg';
import ExternalLinkButton from '../components/ExternalLinkButton';
import { getCurrentUser } from '../redux/slices/myselfSlice';

const PrivacyPage = () => {
  return (
    <div>
      <Navbar
        src={HomePageBG}
        pageName={'Privacy Notice'}
        pageText={
          'SLI respects your privacy and is committed to protecting it to the extent possible, subject to applicable state and federal law, through our compliance with our privacy policies and this Privacy Notice.'
        }
      />
      <ContentBox
        title="Personal information we collect and process"
        content={
          <div>
            <div>
              In this Notice, "personal information" means any information that
              identifies or describes an individual user of the Sites,
              including, but not limited to, the user's name, social security
              number, physical description, address, telephone number,
              education, financial matters, medical or employment history,
              password, email address, individual's photo, and information that
              reveals any network location or identity. If you are located in
              the European Economic Area (EEA), "personal information" includes
              all personal data as defined under EEA laws (including "sensitive
              personal information" which is provided enhanced protections under
              those laws).
            </div>

            <p>&nbsp;</p>

            <div>
              We collect personal information about users only as allowed by law
              and limit the collection of personal information to what is
              relevant and necessary to accomplish a lawful purpose. Our legal
              basis for processing most of this information is to perform a task
              in the public interest, including those set out in the California
              Education Code and/or Title V of the California Code of
              Regulations, or under applicable federal law. Other legal bases
              for processing information include processing necessary for
              contract (e.g., to process parking permit payments), for
              legitimate interests (e.g., to send requested information) or
              consent (e.g., to process certain sensitive personal information).
            </div>

            <p>&nbsp;</p>

            <div>
              <b>User-Provided Information:</b> You may be required to provide
              personal information to access or use certain parts of our Sites,
              or features of our Sites or services, including without
              limitation, when you apply for or enroll at one of our campuses or
              programs, subscribe to a newsletter or email list, make a purchase
              or donation, fill out a form, participate in any of our programs,
              special events or promotions, contact us with a comment, question
              or complaint, etc. If you do not provide the requested personal
              information, you may not be able to access or use the features of
              our Sites or service where such information is requested.
            </div>

            <p>&nbsp;</p>

            <div>
              <b>Emails and Social Media Sites:</b> If you correspond with us by
              email, mail or via social media, we may retain the content of your
              communication or social media posting, the email or social media
              account address from which it is sent, and our response. We
              collect information automatically using technology when you visit
              our Sites or social media pages or when you open one of our emails
              as described in this Notice. The specific personal information
              (and sensitive personal information) we collect, why we collect
              it, and our legal basis for processing it, is periodically
              reassessed in applicable data process flow assessments or Data
              Protection Impact Assessments, as relevant.
            </div>
          </div>
        }
        bgColor={'#CFE3F6'}
        titleColor={'#2E7A32'}
        textAlign={'text-left'}
      />
      <ContentBox
        title="How we use the information we collect"
        titleColor="#2E7A32"
        content={
          <div>
            We use information that we collect about you or that you provide to
            us (or through third-party vendors), including any personal
            information:
            <ul>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                {' '}
                To provide you with information that you request from us.
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                {' '}
                To process application, registration and enrollment requests
                when you apply, register or enroll for our programs or services.
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                {' '}
                To collect and process donations, gifts and donor information.
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                {' '}
                To communicate and work with volunteers (e.g., advisory boards,
                …).
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                {' '}
                To respond to your questions, requests, comments or complaints
                and determine your satisfaction with our events, programs and
                services.
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                {' '}
                To operate, maintain, and provide to you the features, services,
                and functionality of the Sites and its contents, and to monitor
                and improve our site and the user experience.
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                {' '}
                To notify you about changes to our Sites or any services we
                offer or provide through it.
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                {' '}
                To compare and review your personal information for errors,
                omissions and accuracy.
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                {' '}
                To prevent, detect or investigate any fraudulent, abusive or
                illegal act.
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                {' '}
                To allow you to participate in interactive features on our
                Sites.
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                {' '}
                In any other way we may describe when you provide the
                information.
              </li>
              <li className="mb-3" style={{ listStyleType: 'square' }}>
                {' '}
                For any other purpose with your consent.
              </li>
              We may also use your personal information for operational and
              other lawful purposes such as security, analytics, operations,
              fraud detection and prevention, reporting, making back-ups and
              legal compliance.
            </ul>
            We use cookies, clear gifs, and log file information to: (a)
            remember information so that you will not have to re-enter it during
            your visit or the next time you visit the Sites; (b) monitor the
            effectiveness of our Sites and services; (c) monitor aggregate
            metrics such as the total number of visitors and traffic; (d)
            diagnose or fix technology problems reported by our users or
            engineers that are associated with the IP addresses controlled by a
            specific web company or ISP; and (e) help you efficiently access
            information. At the time we collect personal information, we strive
            to tell users about the purpose for which the information is
            collected as well as the general or specific uses that we will make
            of that information.
          </div>
        }
        srcImg={SliProcessContent}
        btnContent={
          <ExternalLinkButton
            href="https://usltrcd-prod-mobile.f2-cloud.com/SelfService/submission/submit/Intake_form"
            target="_blank"
          >
            Contact us to learn more
          </ExternalLinkButton>
        }
        btnColor="#A8C356"
        display="inline-block"
      />
    </div>
  );
};

export default PrivacyPage;
