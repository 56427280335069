import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../api';

export const getAllReservations = createAsyncThunk(
  'reservation/getAllReservations',
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.get('/reservations');
      return res.data.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || error.errors.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  },
);

export const deleteReservation = createAsyncThunk(
  'reservation/deleteReservation',
  async (resId, { rejectWithValue }) => {
    try {
      await api.delete(`/reservations/${resId}`);
      toast.success('Deleted Reservation!');
      return resId;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || error.errors.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  },
);

export const updateReservation = createAsyncThunk(
  'reservation/updateReservation',
  async (reservation, { rejectWithValue }) => {
    const resId = reservation._id;
    try {
      const res = await api.put(`/reservations/${resId}`, reservation);
      // toast.success('Success!');
      return res.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || error.errors.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  },
);

export const createReservation = createAsyncThunk(
  'reservation/createReservation',
  async (reservation, { rejectWithValue }) => {
    try {
      const res = await api.post('/reservations', reservation);
      toast.success('Created Reservation!');
      return res.data.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || error.message || error.errors.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  },
);

const initialState = {
  reservations: [],
  reservation: null,
  loading: false,
  error: null,
};

const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllReservations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllReservations.fulfilled, (state, action) => {
        state.loading = false;
        state.reservations = action.payload;
      })
      .addCase(getAllReservations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createReservation.pending, (state) => {
        state.loading = true;
      })
      .addCase(createReservation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.reservations.push(action.payload);
      })
      .addCase(deleteReservation.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteReservation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.reservations = state.reservations.filter(
          (reservation) => reservation._id !== action.payload,
        );
      })
      .addCase(updateReservation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateReservation.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.reservations = state.reservations.map((reservation) =>
          reservation._id === action.payload._id ? action.payload : reservation,
        );
      });
  },
});

export default reservationSlice.reducer;
