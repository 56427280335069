export default function ReservationInfoModal({
  setShowReservationInfoModal,
  setSelectedReservation,
  reservation,
  renterName,
  equipmentName,
}) {
  const options = {
    day: 'numeric',
    year: 'numeric',
    month: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  if (!reservation || typeof reservation === 'undefined') {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white rounded-lg shadow-xl w-11/12 max-w-md p-6">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">
            There was an error getting this reservations information.
          </h2>
          <div className="mt-6 flex justify-end">
            <button
              className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-5 rounded-md transition duration-200"
              onClick={() => {
                setShowReservationInfoModal(false);
                setSelectedReservation(null);
              }}
              type="button"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-xl w-11/12 max-w-md p-6">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          Reservation Information
        </h2>
        <div className="space-y-2">
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Renter Name:</span>
            <span className="text-gray-900">{renterName}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Equipment:</span>
            <span className="text-gray-900">{equipmentName}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Start Date:</span>
            <span className="text-gray-900">
              {new Date(reservation.startDate).toLocaleString('en-US', options)}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">End Date:</span>
            <span className="text-gray-900">
              {new Date(reservation.endDate).toLocaleString('en-US', options)}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Quantity:</span>
            <span className="text-gray-900">{reservation.rentalTotal}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">
              Delivery Assistance:
            </span>
            <span className="text-gray-900">
              {reservation.deliveryAssistance ? 'Yes' : 'No'}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">
              Pickup Assistance:
            </span>
            <span className="text-gray-900">
              {reservation.pickupAssistance ? 'Yes' : 'No'}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium text-gray-700">Price:</span>
            <span className="text-gray-900">{reservation.rentalPrice}</span>
          </div>
        </div>
        <div className="mt-6 flex justify-end">
          <button
            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-5 rounded-md transition duration-200"
            onClick={() => {
              setShowReservationInfoModal(false);
              setSelectedReservation(null);
            }}
            type="button"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
