import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { updateMyReservation } from '../redux/slices/myselfSlice';

export default function UserCancelReservationModal({
  setCancelModal,
  selectedReservation,
  setSelectedReservation,
}) {
  const dispatch = useDispatch();

  const cancelReservation = async () => {
    if (selectedReservation) {
      try {
        await dispatch(
          updateMyReservation({
            ...selectedReservation,
            reservationStatus: "canceled",
          }),
        );
        setSelectedReservation(null);
        setCancelModal(false);
      } catch (error) {
        toast.error('Could not perform the action. Please try again.');
      }
    } else {
      toast.error('Could not perform the action. Please try again.');
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-md shadow-lg w-1/2 max-w-lg">
        <form>
          <label className="block mb-2 text-lg font-bold">
            Are You Sure You Want To{' '}
            <span className="text-red-500"> Cancel </span> Your Reservation?
          </label>
          <div className="flex justify-end">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md"
              type="button"
              onClick={() => cancelReservation()}
            >
              Confirm
            </button>
            <button
              className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md"
              onClick={() => setCancelModal(false)}
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
