import React, { Fragment, useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import FarmshareBG from '../assets/FarmshareBG.jpg';
import DetailEquipment from '../components/DetailEquipment';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { getSingleEquipment } from '../redux/slices/equipmentsSlice';
import { rentEquipment } from '../redux/slices/equipmentsSlice';
import CreateReservationModal from '../components/CreateReservationModal';

const DetailEquipmentPage = () => {
  const { equipmentId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.myself.user);
  const equipment = useSelector((state) => state.equipments.selectedEquipment);
  const [showCreateReservationModal, setShowCreateReservationModal] =
    useState(false);
  const [quantity, setQuantity] = useState(1);
  const loading = useSelector((state) => state.equipments.loading);
  const error = useSelector((state) => state.equipments.error);

  useEffect(() => {
    dispatch(getSingleEquipment(equipmentId));
  }, [equipmentId, dispatch]);

  return (
    <Fragment>
      {!equipment ? (
        <Navigate to="/farmshareIP" />
      ) : (
        <div className="bg-amber-100">
          <Navbar src={FarmshareBG} pageName={`Farmshare`} />
          <DetailEquipment
            equip={equipment}
            showResModal={setShowCreateReservationModal}
            setQuantity={setQuantity}
            quantity={quantity}
          />
          {showCreateReservationModal && (
            <CreateReservationModal
              setShowCreateReservationModal={setShowCreateReservationModal}
              userId={user._id}
              renterName={user.firstName + ' ' + user.lastName}
              equipment={equipment}
              quantity={quantity}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default DetailEquipmentPage;
