import Navbar from '../components/Navbar';
import FarmShareBG from '../assets/FarmshareBG.jpg';
import Input from '../components/Input';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUser } from '../redux/slices/myselfSlice';
import { useNavigate } from 'react-router-dom';

export default function UserProfilePage() {
  const [currentSection, setCurrentSection] = useState('info');
  const user = useSelector((state) => state.myself.user);
  const loading = useSelector((state) => state.myself.loading);
  const error = useSelector((state) => state.myself.error);
  const navigate = useNavigate();

  console.log(user);
  if (!user) navigate('/login');

  return (
    <div>
      <Navbar src={FarmShareBG} pageName={`User Profile`} pageText={''} />
      <div className="bg-white h-[2rem]" />
      {!loading && user && (
        <div className="flex px-[8vw] md:px-16 lg:px-[10vw] bg-sky-200">
          <div className="my-7 bg-white w-full shadow-md grid grid-cols-1 lg:grid-cols-5">
            <div className="col-span-1 border border-r-1 flex lg:block">
              <button
                onClick={() => {
                  setCurrentSection('info');
                }}
                className={`px-5 py-3 sm:py-5 border-b w-full text-left ${
                  currentSection === 'info' ? 'bg-gray-100 border' : 'bg-white'
                }`}
              >
                Edit Profile
              </button>
              <button
                onClick={() => {
                  setCurrentSection('creds');
                }}
                className={`px-5 py-3 sm:py-5 border-b w-full text-left  ${
                  currentSection === 'creds' ? 'bg-gray-100 border' : 'bg-white'
                }`}
              >
                Update Password
              </button>
            </div>
            <div className="col-span-4">
              {currentSection === 'info' && <UserInfoForm user={user} />}
              {currentSection === 'creds' && (
                <UpdatePasswordForm
                  user={user}
                  setCurrentSection={setCurrentSection}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="flex justify-center items-center w-full h-full">
          <p className="text-gray-700">Loading reservations...</p>
        </div>
      )}
      {error && !user && (
        <div className="flex justify-center items-center w-full h-full">
          <p className="text-red-600 font-bold">{error} - Please try again</p>
        </div>
      )}
    </div>
  );
}

function UserInfoForm({ user }) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    address: user.address,
    city: user.city,
    zip: user.zip,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    dispatch(
      updateCurrentUser({
        firstName: formData.firstName,
        lastName: formData.lastName,
        address: formData.address,
        city: formData.city,
        zip: formData.zip,
      }),
    );
  };

  return (
    <div className="w-full bg-white my-8 py-3 px-[6%] rounded-lg">
      <h2 className="text-2xl font-semibold mb-6">Edit Profile</h2>
      <form onSubmit={handleUpdateUser}>
        <div className="flex grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
          <Input
            name="firstName"
            type="text"
            label="First Name"
            value={formData.firstName}
            onChange={handleInputChange}
            className="w-auto"
          />
          <Input
            name="lastName"
            type="text"
            label="Last Name"
            value={formData.lastName}
            onChange={handleInputChange}
          />
        </div>
        <Input
          name="address"
          type="text"
          label="Address"
          value={formData.address}
          onChange={handleInputChange}
        />
        <Input
          name="city"
          type="text"
          label="City"
          value={formData.city}
          onChange={handleInputChange}
        />
        <Input
          name="zip"
          type="text"
          label="Zip Code"
          value={formData.zip}
          onChange={handleInputChange}
        />
        <button
          type="submit"
          className="bg-[#2E7A32] text-white py-2 px-6 mt-5 rounded hover:bg-[#236326] transition duration-200"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

function UpdatePasswordForm({ setCurrentSection }) {
  const dispatch = useDispatch();
  const [isMatch, setIsMatch] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState('');

  const [formData, setFormData] = useState({
    oldPassword: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdatePass = async (e) => {
    e.preventDefault();
    dispatch(
      updateCurrentUser({
        oldPassword: formData.oldPassword,
        password: confirmPassword,
      }),
    ).then(() => {
      setCurrentSection('info');
    });
  };

  useEffect(() => {
    if (formData.password !== confirmPassword) {
      setIsMatch(false);
    } else {
      setIsMatch(true);
    }
  }, [confirmPassword, formData.password]);

  return (
    <div className="w-full bg-white my-8 py-3 px-[6%] rounded-lg">
      <h2 className="text-2xl font-semibold mb-6">Update Password</h2>
      <form onSubmit={handleUpdatePass}>
        <Input
          name="oldPassword"
          type="password"
          label="Current Password"
          value={formData.oldPassword}
          onChange={handleInputChange}
        />
        <div className="flex grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
          <Input
            name="password"
            type="password"
            label="New Password"
            value={formData.password}
            onChange={handleInputChange}
          />
          <Input
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
        </div>
        {!isMatch && (
          <p className="text-red-500 text-sm mt-2"> Password did not match</p>
        )}
        <button
          type="submit"
          className="bg-[#2E7A32] text-white py-2 px-4 mt-4 rounded hover:bg-[#236326] transition duration-200"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
